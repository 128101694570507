<template>
  <div class="change-password-form-content">
    <div class="change-password-form-header">
      Create your password
    </div>
    <div class="change-password-form-header-description">
      Almost ready, time to create your new password.
    </div>
    <form
      class="change-password-form"
      @submit.prevent="changePassword"
    >
      <div class="change-password-form-label">
        Your username
      </div>
      <input
        type="text"
        class="username-field"
        :value="username"
        disabled
        autocomplete="username"
      >
      <div class="change-password-form-label">
        New password
      </div>
      <div class="change-password-form-input password first">
        <input
          :type="!passwordVisible ? 'password' : 'text'"
          placeholder="Insert your password"
          v-model="formData.newPassword"
          @input="resetErrorMessage"
        >
        <div
          class="visibility-button"
          @click="togglePassVisibility"
        >
          <EyeIcon v-if="!passwordVisible" />
          <EyeOffIcon v-else />
        </div>
      </div>
      <PasswordPolicy
        :password="formData.newPassword"
      />
      <div class="change-password-form-label">
        Repeat password
      </div>
      <div class="change-password-form-input password">
        <input
          :type="!passwordVisible ? 'password' : 'text'"
          placeholder="Insert your password"
          v-model="formData.repeatedPassword"
          @input="resetErrorMessage"
        >
        <div
          class="visibility-button"
          @click="togglePassVisibility"
        >
          <EyeIcon v-if="!passwordVisible" />
          <EyeOffIcon v-else />
        </div>
      </div>
      <div
        class="change-password-error-message"
        v-if="errorMessage"
      >
        {{ errorMessage }}
      </div>
      <div class="change-password-form-footer">
        <button
          type="submit"
          :class="['change-password-btn', {'disabled': !!errorMessage || changingPasswordProcessing}]"
          :disabled="!!errorMessage || changingPasswordProcessing"
        >
          Create password
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid';
import PasswordPolicy from './PasswordPolicy';

export default {
  components: {
    EyeIcon,
    EyeOffIcon,
    PasswordPolicy,
  },
  props: {
    username: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const passwordVisible = ref(false);
    const togglePassVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };
    const errorMessage = ref('');
    const resetErrorMessage = () => {
      if (errorMessage.value) errorMessage.value = '';
    };
    const changePasswordAttributes = computed(() => store.getters.changePasswordAttributes);

    const formData = ref({
      newPassword: '',
      repeatedPassword: '',
    });

    const deviceRemembered = ref(true);
    const changingPasswordProcessing = ref(false);

    const changePassword = () => {
      if (changingPasswordProcessing.value) return;
      resetErrorMessage();
      changingPasswordProcessing.value = true;
      if (formData.value.newPassword !== formData.value.repeatedPassword) {
        errorMessage.value = 'The password you entered does not match';
        changingPasswordProcessing.value = false;
        return;
      }
      store.dispatch('changePassword', {
        newPassword: formData.value.newPassword,
        userAttributes: changePasswordAttributes.value,
      })
        .then(() => {
          store.dispatch('login', { username: props.username, password: formData.value.newPassword })
            .then(() => {
              changingPasswordProcessing.value = false;
              router.push({ name: 'events' });
              store.dispatch('changePasswordAttributes', null);
            })
            .catch(() => {
              changingPasswordProcessing.value = false;
              store.dispatch('changePasswordAttributes', null);
            });
        })
        .catch((err) => {
          changingPasswordProcessing.value = false;
          errorMessage.value = err.message;
        });
    };

    return {
      formData,
      changePassword,
      passwordVisible,
      togglePassVisibility,
      errorMessage,
      resetErrorMessage,
      deviceRemembered,
      changingPasswordProcessing,
    };
  },
};
</script>

<style lang="scss">

.change-password-form-content {
  display: flex;
  flex-direction: column;

  .change-password-form-header {
    color: #191414;
    font-family: Poppins;
    font-size: 19.8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }

  .change-password-form-header-description {
    color: #6D6D6D;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    margin-bottom: 40px;
  }

  .change-password-form {
    display: flex;
    flex-direction: column;

    .change-password-form-label {
      overflow: hidden;
      color: var(--neutral-text-medium-resting, #6D6D6D);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    .username-field {
      display: flex;
      height: 44px;
      min-width: 24px;
      padding: 0px 8px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 4px;
      background: #F0F0F0;
      border: none;
      margin-bottom: 24px;
      cursor: not-allowed;
      overflow: hidden;
      color: #A9A9A9;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
    }

    .change-password-form-input {
      display: flex;
      margin-bottom: 24px;
      height: 44px;

      &.password {
        position: relative;
        margin-bottom: 0;

        &.first {
          margin-bottom: 24px;
        }
      }

      input {
        height: 100%;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        border: none;
        outline: none;
        border-radius: 4px;
        border: 1px solid #F0F0F0;
        background: #FFF;
        box-shadow: none;
        outline-offset: 1px;
        padding: 0 8px;

        &::placeholder {
          color: #CDCDCD;
        }

        &:hover {
          border-color: #CDCDCD;
        }

        &:focus-visible {
          border-color: #CDCDCD;
          outline: 2px solid #A2BFFF;
        }
      }

      .visibility-button {
        height: 43px;
        width: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;

        svg {
          height: 20px;
          width: 20px;
          color: #000;
        }
      }
    }

    .change-password-error-message {
      font-size: 12px;
      line-height: 14px;
      margin-top: 8px;
      color: #FF2E2D;
    }

    .change-password-form-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 40px;

      .remember-device {
        height: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .remember-icon {
          height: 100%;
          width: 20px;
          border: 1px solid #003C3C;
          box-sizing: border-box;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.active {
            background: #003C3C;
          }

          svg {
            height: 18px;
            width: 18px;
            color: #fff;
          }
        }

        .remember-label {
          padding: 0 8px;
          height: 100%;
          line-height: 20px;
          font-size: 12px;
          color: #6B6B6B;
        }
      }

      .change-password-btn {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #003C3C;
        border-radius: 6px;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        padding: 0 8px;
        width: 100%;

        &.disabled {
          cursor: default;
          opacity: 0.6;
        }
      }
    }
  }

  .password-policy {
    margin-top: -24px;
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 650px) {
  .change-password-form-content {
    .change-password-form {
      .change-password-form-footer {
        .change-password-btn {
          width: auto;
          margin-left: auto;
        }
      }
    }
  }
}
</style>
